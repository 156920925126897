// src/screens/DashboardScreen.js
import React from "react";
import { Box } from "@mui/material";
import Dashboard from "../components/Dashboard";

const DashboardScreen = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <Dashboard />
    </Box>
  );
};

export default DashboardScreen;
