import { Typography, Grid } from "@mui/material";
import React from "react";

const OrderSummary = ({ subTotal, fees, items, ticketCounts }) => {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: 20,
      }}
    >
      <Typography style={{ fontWeight: "bold", marginBottom: 20 }}>
        Order Summary
      </Typography>
      <Grid
        container
        xs={12}
        style={{
          borderBottom: "1px solid lightgrey",
        }}
      >
        {ticketCounts.map((count, idx) => {
          if (count > 0) {
            return (
              <Grid
                item
                container
                xs={12}
                style={{
                  padding: 10,
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {count}x {items[idx].name}
                </Typography>
                <Typography>
                  ${(count * items[idx].price).toFixed(2)}
                </Typography>
              </Grid>
            );
          }
        })}
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{}}>Subtotal</Typography>
        <Typography style={{}}>${subTotal.toFixed(2)}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{}}>Fees</Typography>
        <Typography style={{}}>${fees.toFixed(2)}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
          borderTop: "1px solid lightgrey",
          marginTop: 20,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Total
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          ${(subTotal + fees).toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
