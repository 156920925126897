import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Modal,
  Paper,
  Radio,
  TextField,
  Tooltip,
  Typography,
  Switch,
} from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import {
  CheckBox,
  Close,
  Edit,
  Info,
  InfoOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import PlacesAutoComplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AppContext } from "../App";
import { TimePicker } from "@mui/x-date-pickers";
import Toast from "./Toast";
import firebase, { db, functions, storage } from "../config/firebaseConfig";

const ViewEditEvent = ({ open, setClose, event, setEvents, events }) => {
  const { defaultTheme, setError, setMessage, setSuccess, userData } =
    useContext(AppContext);
  const [testMode, setTestMode] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionTeaser, setDescriptionTeaser] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedLocationType, setSelectedLocationType] =
    useState("physical location");
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState("");

  //! Tickets
  const [tickets, setTickets] = useState([]);
  const [ticketName, setTicketName] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketLimit, setTicketLimit] = useState("");
  const [discounted, setDiscounted] = useState(false);
  const [discountedValue, setDiscountedValue] = useState(0);
  const [absorbFees, setAbsorbFees] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [importOpen, setImportOpen] = useState(false);
  const [addPromoterModalOpen, setAddPromoterModalOpen] = useState(false);
  const [importUrl, setImportUrl] = useState("");
  const [loadingScraper, setLoadingScraper] = useState(false);
  const [importedEvent, setImportedEvent] = useState(null);
  const [userIsPromoter, setUserIsPromoter] = useState(null);
  const [promoterEmail, setPromoterEmail] = useState("");
  const [promoter, setPromoter] = useState(null);

  const handleAddTicket = () => {
    const newTicket = {
      discountValue: discounted ? discountedValue : 0,
      price: ticketPrice,
      sold: 0,
      description: ticketDescription,
      discountType: discounted ? discountType : "",
      name: ticketName,
      remaining: ticketQuantity,
      quantity: ticketQuantity,
      limit: ticketLimit,
      discounted: discounted,
      absorbFees: absorbFees,
    };
    setTickets([...tickets, newTicket]);
    // Clear ticket input fields
    setTicketName("");
    setTicketPrice("");
    setTicketQuantity("");
    setTicketDescription("");
    setTicketLimit("");
    setAbsorbFees(false);
    setDiscounted(false);
    setDiscountedValue(0);
    setDiscountType("");
  };

  const handleDeleteTicket = (index) => {
    const newTickets = tickets.filter((_, i) => i !== index);
    setTickets(newTickets);
  };

  const handleChangeLocationType = (event) => {
    if (event.target.value === "tbd") {
      setLocation("Location is TBD");
      setCoordinates({ lat: null, lng: null });
    } else {
      setLocation("");
      setCoordinates({ lat: null, lng: null });
    }
    setSelectedLocationType(event.target.value);
  };

  const handleChange = async (value) => {
    setLocation(value);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);

    setLocation(value);
    setCoordinates(latLng);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const saveOrPuplishEvent = async (isDraft) => {
    if (event) {
      console.log(event);
      console.log("Image File", imageFile);
      let dbImage;
      if (imageFile) {
        let imagePath = storage.ref(
          `artists/eventImages/${userData.uid}/${imageFile.name}`
        );
        let uploadTask = imagePath.put(imageFile);
        await uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGE,
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log("Upload is " + progress + "% done");
          }
        );
        await uploadTask
          .then((snapshot) => {
            console.log("Uploaded a file! Image URL will be here.");
            return imagePath.getDownloadURL();
          })
          .then((downloadURL) => {
            dbImage = downloadURL;
          });
      }
      // Update event
      let selectedEvent = events.find((e) => e.id === event.id);
      selectedEvent.title = title;
      selectedEvent.description = description;
      selectedEvent.teaser = descriptionTeaser;
      selectedEvent.startDate =
        date !== "" && time !== ""
          ? new Date(date + " " + time)
          : new Date(selectedEvent.startDate.seconds * 1000);
      selectedEvent.endDate =
        endDate !== "" && endTime !== ""
          ? new Date(endDate + " " + endTime)
          : new Date(selectedEvent.endDate.seconds * 1000);
      selectedEvent.location = location;
      selectedEvent.coordinates = coordinates;
      selectedEvent.image = dbImage ? dbImage : image;
      selectedEvent.tickets = tickets;
      selectedEvent.draft = false;

      await db
        .collection("events")
        .doc(event.id)
        .update(
          {
            ...event,
            title: selectedEvent.title,
            description: selectedEvent.description,
            teaser: selectedEvent.teaser,
            startDate: selectedEvent.startDate,
            endDate: selectedEvent.endDate,
            location: selectedEvent.location,
            coordinates: selectedEvent.coordinates,
            image: selectedEvent.image,
            tickets: selectedEvent.tickets,
            draft: selectedEvent.draft,
          },
          { merge: true }
        )
        .then(() => {
          setSuccess(true);
          setMessage(
            isDraft ? "Event saved successfully" : "Event updated successfully"
          );
          setEvents([...events]);
          setClose();
        })
        .catch((error) => {
          console.log("THERE IS AN ERR => ", error);
          setError(true);
          setMessage("Something went wrong");
        });
    } else {
      // Add event
      console.log("Image File", imageFile);
      let dbImage;
      if (imageFile) {
        let imagePath = storage.ref(
          `artists/eventImages/${userData.uid}/${imageFile.name}`
        );
        let uploadTask = imagePath.put(imageFile);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGE, (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload is " + progress + "% done");
        });
        uploadTask
          .then((snapshot) => {
            console.log("Uploaded a file! Image URL will be here.");
            return imagePath.getDownloadURL();
          })
          .then((downloadURL) => {
            dbImage = downloadURL;
          });
      }

      let newEvent = {
        artistId: userData.uid,
        coordinates,
        description: description,
        endDate:
          endDate !== "" && endTime !== ""
            ? new Date(endDate + " " + endTime)
            : new Date(),
        id:
          Math.floor(Math.random() * 1000000000).toString() +
          "-" +
          userData.uid,
        image: dbImage ? dbImage : image,
        location: location,
        startDate:
          date !== "" && time !== "" ? new Date(date + " " + time) : new Date(),
        teaser: descriptionTeaser,
        tickets: tickets,
        title: title,
        draft: isDraft ? true : false,
        isHidden: false,
        promoter: {
          name: promoter ? promoter.name : userData.name,
          email: promoter ? promoter.email : userData.email,
          phone: promoter ? promoter.phone : userData.phone,
          uid: promoter ? promoter.uid : userData.uid,
        },
        test: testMode,
      };

      console.log(newEvent, time, date);

      await db
        .collection("events")
        .doc(newEvent.id)
        .set(newEvent)
        .then(function () {
          setSuccess(true);
          setMessage(
            isDraft ? "Event saved successfully" : "Event created successfully"
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
          setError(true);
          setMessage("Error adding event, please try again.");
        });

      setEvents([...events, newEvent]);
      setClose();
    }
  };

  const handleImageSelect = async (e) => {
    console.log("this is the e", e);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      //set file to state for upload
      setImageFile(file);
      const reader = await new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("result", reader.result);
        setImage(reader.result);
        setImageSrc(reader.result);
      };
    }
  };

  const handleConfirmPromoter = async () => {
    let updatedEvent = {
      ...event,
      promoter: {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        uid: userData.uid,
      },
    };

    try {
      await db
        .collection("events")
        .doc(event.id)
        .update({
          promoter: {
            name: userData.name,
            email: userData.email,
            phone: userData.phone,
            uid: userData.uid,
          },
        })
        .then(() => {
          //update events
          setEvents(events.map((e) => (e.id === event.id ? updatedEvent : e)));
          setSuccess(true);
          setMessage("Promoter updated successfully");
          setClose();
        });
    } catch (error) {
      console.log(error);
    }

    setEvents();
  };

  const handleClose = () => {
    // Reset form values
    setTitle("");
    setDescription("");
    setDescriptionTeaser("");
    setDate("");
    setTime("");
    setEndDate("");
    setEndTime("");
    setPromoter(null);
    setLocation("");
    setCoordinates({ lat: null, lng: null });
    setImage("");
    setImageFile(null);
    setImageSrc("");
    setTickets([]);
    setClose();
    setImportUrl("");
    setPromoterEmail("");
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) && email.length < 255 && email.length > 0;
  };

  const handleImportEvent = async () => {
    //call cloud function to scrape eventbrite or ticketshare
    console.log("button clicked");
    setLoadingScraper(true);
    try {
      if (importUrl.includes("eventbrite")) {
        const scrapeEventbrite = functions.httpsCallable(
          "scrapeEventbriteEvent"
        );
        const res = await scrapeEventbrite({
          url: importUrl.split("?")[0],
        });

        if (res.data) {
          let importedEvent = res.data;
          console.log(res);
          //   setSuccess(true);
          //   setMessage("Event imported successfully");
          //   setLoadingScraper(false);
          setImportedEvent(importedEvent);
          //   setTitle(importedEvent.title);
          //   setDescription(importedEvent.description);
          //   setDescriptionTeaser(importedEvent.teaser);
          //   setDate(`${new Date(importedEvent.startDate).toLocaleDateString()}`);
          //   setTime(new Date(importedEvent.startDate).toLocaleTimeString());
          //   setEndDate(`${new Date(importedEvent.endDate).toLocaleDateString()}`);
          //   setEndTime(new Date(importedEvent.endDate).toLocaleTimeString());
          //   setLocation(importedEvent.location?.split("Show map")[0]);
          //   setCoordinates(importedEvent.coordinates)
          //   setImage(importedEvent.image);
          setAddPromoterModalOpen(true);
          //   setImportOpen(false);
        } else {
          setLoadingScraper(false);
          setError(true);
          setMessage("Error importing event, please try again.");
          setLoadingScraper(false);
        }
      }
    } catch (error) {
      console.error("Error => ", error);
    }
  };

  const handleAddPromoter = async () => {
    let foundPromoter;

    if (userIsPromoter) {
      foundPromoter = {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        uid: userData.uid,
      };
    } else {
      foundPromoter = await db
        .collection("promoters")
        .where("email", "==", promoterEmail)
        .get()
        .then((query) => {
          if (query.empty) {
            return {
              name: "",
              email: promoterEmail,
              phone: "",
              uid: "",
            };
          } else {
            return query.docs.map((doc) => doc.data())[0];
          }
        });
    }

    setPromoter(foundPromoter);

    if (importedEvent) {
      setLoadingScraper(false);
      setImportedEvent(importedEvent);
      setTitle(importedEvent.title);
      setDescription(importedEvent.description);
      setDescriptionTeaser(importedEvent.teaser);
      setDate(`${new Date(importedEvent.startDate).toLocaleDateString()}`);
      setTime(new Date(importedEvent.startDate).toLocaleTimeString());
      setEndDate(`${new Date(importedEvent.endDate).toLocaleDateString()}`);
      setEndTime(new Date(importedEvent.endDate).toLocaleTimeString());
      setLocation(importedEvent.location?.split("Show map")[0]);
      // setCoordinates(importedEvent.coordinates)
      setImage(importedEvent.image);
      setImportOpen(false);
    }
    setSuccess(true);
    setMessage("Promoter added and Event imported successfully");
    setAddPromoterModalOpen(false);
  };

  useEffect(() => {
    if (event) {
      console.log(new Date(event.startDate.seconds));
      setTitle(event.title);
      setDescription(event.description);
      setDescriptionTeaser(event.teaser);
      setDate(new Date(event.startDate.seconds * 1000).toLocaleDateString());
      setTime(
        `${new Date(event.startDate.seconds * 1000).toLocaleTimeString()}`
      );
      setEndDate(
        `${new Date(event.endDate.seconds * 1000).toLocaleDateString()}`
      );
      setEndTime(
        `${new Date(event.endDate.seconds * 1000).toLocaleTimeString()}`
      );
      setLocation(event.location);
      setImage(event.image);
      setTickets(event.tickets);
      if (event.promoter) {
        setPromoter(event.promoter);
      } else {
        setPromoter({
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          uid: userData.uid,
        });
      }
    }
  }, [event]);

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Toast />
        <Grid
          item
          container
          xs={11}
          md={10}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "lightgrey",
            borderRadius: "12px",
            padding: { xs: "10px", md: "20px" },
            height: "fit-content",
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: 10 }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              sx={{
                textAlign: { xs: "center", md: "left" },
                width: { xs: "100%", md: "49%" },
              }}
            >
              {event ? "Edit event" : "Create event"}
              {!event && (
                <Tooltip title="This event will not be live. Only you will see this.">
                  <FormControlLabel
                    style={{ marginLeft: 5 }}
                    label={<Typography variant="caption">Test Mode</Typography>}
                    control={
                      <Switch
                        checked={testMode}
                        onChange={() => setTestMode(!testMode)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </Tooltip>
              )}
            </Typography>

            <Grid
              item
              container
              xs={12}
              md={6}
              sx={{ justifyContent: { xs: "space-evenly", md: "flex-end" } }}
            >
              <Tooltip title="Clear changes">
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              </Tooltip>
              <Tooltip title="Save as Draft and close">
                <Button
                  variant="contained"
                  onClick={() => saveOrPuplishEvent(true)}
                  style={{ margin: "0px 5px" }}
                >
                  {event ? "Save" : "Create Draft"}
                </Button>
              </Tooltip>
              {event && (
                <Tooltip title="All changes will be live">
                  <Button
                    disabled={
                      !title ||
                      !date ||
                      !time ||
                      !endDate ||
                      !endTime ||
                      (date &&
                        time &&
                        endDate &&
                        endTime &&
                        new Date(`${date} ${time}`) >=
                          new Date(`${endDate} ${endTime}`)) ||
                      !location ||
                      tickets.length === 0
                    }
                    variant="contained"
                    onClick={saveOrPuplishEvent}
                  >
                    Publish
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              padding: "10px",
              maxHeight: { xs: "70dvh", md: "80dvh" },
              overflowY: "scroll",
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                borderRadius: 12,
                background: "white",
                padding: 10,
                height: "fit-content",
              }}
            >
              <Grid
                item
                container
                xs={12}
                style={{
                  justifyContent: "space-between",
                  borderBottom: "1px solid grey",
                  paddingBottom: 5,
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  General Info
                </Typography>

                <Button
                  onClick={() => setImportOpen(true)}
                  variant="contained"
                  disabled={userData?.worksWithPromoters !== "yes"}
                >
                  Import Event
                </Button>
              </Grid>
              <Grid item container xs={12} style={{ padding: 10 }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={8}
                  style={{ padding: 10, position: "relative" }}
                >
                  <Typography variant="caption" style={{ width: "100%" }}>
                    Event Name
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter the name of your event..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ marginBottom: 10 }}
                  />
                  <Typography variant="caption" style={{ width: "100%" }}>
                    Short teaser (optional)
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    placeholder="Add a few words under the name of the event, to inspire guest, e.g., An unforgettable show!"
                    value={descriptionTeaser}
                    onChange={(e) => setDescriptionTeaser(e.target.value)}
                    style={{ marginBottom: 10 }}
                  />

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ display: { xs: "none", md: "block" } }}
                    style={{
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      alignItems: "center",
                    }}
                  >
                    {promoter ? (
                      <>
                        <Typography
                          variant="caption"
                          style={{ marginRight: 5 }}
                        >
                          {promoter.name !== "" ? (
                            `Promoter: ${promoter.name} (${promoter.email})`
                          ) : (
                            <>
                              <b>Promoter</b>: {promoter.email} <i>(pending)</i>
                              <>
                                {promoter.email === userData.email && (
                                  <Button
                                    style={{ marginLeft: 5 }}
                                    onClick={handleConfirmPromoter}
                                  >
                                    Confirm
                                  </Button>
                                )}
                              </>
                            </>
                          )}
                        </Typography>
                        {promoter.name !== "" ? (
                          <IconButton
                            onClick={() => setAddPromoterModalOpen(true)}
                          >
                            <Edit />
                          </IconButton>
                        ) : (
                          <Tooltip title="Once you have added a promoter, they can not be removed.">
                            <Info />
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="caption"
                          style={{ marginRight: 5 }}
                        >
                          <b>Promoter</b>: ME
                        </Typography>
                        <Tooltip title="Once you have added a promoter, they can not be removed.">
                          <IconButton
                            onClick={() => setAddPromoterModalOpen(true)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  sx={{ height: { xs: "20dvh", lg: "350px" } }}
                  style={{ padding: 10 }}
                >
                  <Typography variant="caption" style={{ width: "100%" }}>
                    Event Image
                  </Typography>
                  <motion.div
                    whileHover={{
                      opacity: 0.75,
                    }}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      height: "100%",
                      padding: 10,
                      border: "2px dashed lightgrey",
                      position: "relative",
                    }}
                  >
                    <motion.div
                      whileHover={{ opacity: 1 }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: 0.5,
                      }}
                      onDrop={handleImageSelect}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        {image ? "Change" : "Add"}
                      </Button>
                    </motion.div>
                    {image && (
                      <img
                        src={image}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </motion.div>
                  <input
                    ref={fileInputRef}
                    onChange={handleImageSelect}
                    type="file"
                    //accept png, jpg, jpeg, gif only
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    style={{ display: "none" }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sx={{ display: { xs: "block", md: "none" } }}
                style={{
                  marginTop: 10,
                }}
              >
                {promoter ? (
                  <>
                    <Typography variant="caption" style={{ marginRight: 5 }}>
                      {promoter.name !== "" ? (
                        `Promoter: ${promoter.name} (${promoter.email})`
                      ) : (
                        <>
                          <b>Promoter</b>: {promoter.email} <i>(pending)</i>
                          <>
                            {promoter.email === userData.email && (
                              <Button
                                style={{ marginLeft: 5 }}
                                onClick={handleConfirmPromoter}
                              >
                                Confirm
                              </Button>
                            )}
                          </>
                        </>
                      )}
                    </Typography>
                    {promoter.name !== "" ? (
                      <IconButton onClick={() => setAddPromoterModalOpen(true)}>
                        <Edit />
                      </IconButton>
                    ) : (
                      <Tooltip title="Once you have added a promoter, they can not be removed.">
                        <Info />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    <Typography variant="caption" style={{ marginRight: 5 }}>
                      <b>Promoter</b>: ME
                    </Typography>
                    <Tooltip title="Once you have added a promoter, they can not be removed.">
                      <IconButton onClick={() => setAddPromoterModalOpen(true)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={{
                borderRadius: 12,
                background: "white",
                padding: 10,
                marginTop: 20,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                }}
              >
                Date and time
              </Typography>
              <Grid item container xs={12} style={{ padding: 10 }}>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <Typography variant="caption" style={{ width: "100%" }}>
                    Start date
                  </Typography>
                  <Grid
                    item
                    container
                    xs={12}
                    md={8}
                    justifyContent={"space-between"}
                  >
                    {date && time ? (
                      <>
                        <Typography>{date + " " + time}</Typography>
                        <IconButton
                          onClick={() => {
                            setDate("");
                            setTime("");
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          style={{ width: "60%" }}
                        >
                          <DatePicker
                            label="Start Date"
                            onChange={(e) => {
                              console.log(`${e.$d}`.split(" 00:00:00 ")[0]);
                              setDate(`${e.$d}`.split(" 00:00:00 ")[0]);
                            }}
                          />
                        </LocalizationProvider>

                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          style={{ width: "60%" }}
                        >
                          <TimePicker
                            label="Start Time"
                            onChange={(e) => {
                              setTime(`${e.$H}:${e.$m}:00`);
                              console.log(e);
                            }}
                          />
                        </LocalizationProvider>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} style={{ padding: 10 }}>
                  <Typography variant="caption" style={{ width: "100%" }}>
                    End date
                  </Typography>
                  <Grid item container xs={12} justifyContent={"space-between"}>
                    {endDate && endTime ? (
                      <>
                        <Typography>{endDate + " " + endTime}</Typography>
                        <IconButton
                          onClick={() => {
                            setEndDate("");
                            setEndTime("");
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          style={{ width: "60%" }}
                        >
                          <DatePicker
                            label="End Date"
                            onChange={(e) => {
                              if (
                                new Date(`${e.$d}`).getTime() <
                                new Date(date).getTime()
                              ) {
                                setError(true);
                                setMessage(
                                  "End date cannot be before start date"
                                );
                              } else {
                                setEndDate(`${e.$d}`.split(" 00:00:00 ")[0]);
                              }
                            }}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          style={{ width: "60%" }}
                        >
                          <TimePicker
                            label="End Time"
                            onChange={(e) => {
                              if (
                                new Date(`${date} ${time}`) >=
                                new Date(`${endDate} ${e.$H}:${e.$m}:00`)
                              ) {
                                setError(true);
                                setMessage(
                                  "End time cannot be before start time"
                                );
                              } else {
                                setEndTime(`${e.$H}:${e.$m}:00`);
                              }
                              console.log(e);
                            }}
                          />
                        </LocalizationProvider>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={{
                borderRadius: 12,
                background: "white",
                padding: 10,
                marginTop: 20,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                }}
              >
                Location
              </Typography>

              <Grid item container xs={12} style={{ padding: 10 }}>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <Typography variant="caption" style={{ width: "100%" }}>
                    Select one
                  </Typography>
                  <Grid
                    item
                    container
                    xs={12}
                    md={8}
                    justifyContent={"space-between"}
                  >
                    {/*  */}
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        style={{
                          border:
                            selectedLocationType === "physical location"
                              ? "2px solid red"
                              : "1px solid lightgrey",
                          borderRadius: 8,
                          height: "fit-content",
                          padding: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            checked={
                              selectedLocationType === "physical location"
                            }
                            onChange={handleChangeLocationType}
                            value="physical location"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Physical location" }}
                            style={{
                              padding: 0,
                              color:
                                selectedLocationType === "physical location"
                                  ? "red"
                                  : "",
                            }}
                            //   color="red"
                          />
                          <Typography style={{ marginLeft: 5 }}>
                            Physical location
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        style={{
                          border:
                            selectedLocationType === "online"
                              ? "2px solid red"
                              : "1px solid lightgrey",
                          borderRadius: 8,
                          height: "fit-content",
                          padding: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            checked={selectedLocationType === "online"}
                            onChange={handleChangeLocationType}
                            value="online"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Online" }}
                            style={{
                              padding: 0,
                              color:
                                selectedLocationType === "online" ? "red" : "",
                            }}
                          />
                          <Typography style={{ marginLeft: 5 }}>
                            Online
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      style={{
                        padding: 10,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        style={{
                          border:
                            selectedLocationType === "tbd"
                              ? "2px solid red"
                              : "1px solid lightgrey",
                          borderRadius: 8,
                          height: "fit-content",
                          padding: 10,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            checked={selectedLocationType === "tbd"}
                            onChange={handleChangeLocationType}
                            value="tbd"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "TBD" }}
                            style={{
                              padding: 0,
                              color:
                                selectedLocationType === "tbd" ? "red" : "",
                            }}
                          />
                          <Typography style={{ marginLeft: 5 }}>
                            To be decided (TBD)
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    {/*  */}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} style={{ padding: 10 }}>
                  <Typography variant="caption" style={{ width: "100%" }}>
                    {selectedLocationType === "physical location"
                      ? "Event location"
                      : selectedLocationType === "online"
                      ? "Event URL"
                      : "Display a message"}
                  </Typography>
                  <Grid item container xs={12} justifyContent={"space-between"}>
                    {selectedLocationType === "physical location" ? (
                      <PlacesAutoComplete
                        value={location}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        style={{ width: "inherit" }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ width: "inherit" }}>
                            <Paper
                              elevation={0}
                              component="form"
                              style={{
                                marginLeft: 5,
                                flex: 1,
                                display: "flex",
                                height: 50,
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid lightgrey",
                                borderRadius: 5,
                              }}
                            >
                              <InputBase
                                aria-controls="simple-menu"
                                onClick={handleClick}
                                {...getInputProps({
                                  placeholder: "Enter Your Address...",
                                })}
                                style={{
                                  marginLeft: 5,
                                  flex: 1,
                                  display: "flex",
                                  height: 50,
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              />

                              {/* <LocationOnOutlined
								style={{
								  color: defaultTheme.palette.primary.main,
								  fontSize: "40px",
								  cursor: "pointer",
								}}
							  /> */}
                            </Paper>

                            <div
                              id="simple-menu"
                              anchorEl={anchorEl}
                              style={{
                                background: "white",
                              }}
                            >
                              {loading && <MenuItem>Loading...</MenuItem>}
                              {suggestions.map((suggestion) => {
                                const style = suggestion.active
                                  ? {
                                      backgroundColor:
                                        defaultTheme.palette.primary.light,
                                      cursor: "pointer",
                                    }
                                  : { backgroundColor: "white" };

                                return (
                                  <MenuItem
                                    style={{ background: "white" }}
                                    onClick={handleClose}
                                    key={suggestion.description}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </MenuItem>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutoComplete>
                    ) : (
                      <TextField
                        placeholder={
                          selectedLocationType === "online"
                            ? "Enter your event URL"
                            : "Location is TBD..."
                        }
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        style={{ marginBottom: 10, width: "100%" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={{
                borderRadius: 12,
                background: "white",
                padding: 10,
                marginTop: 20,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                }}
              >
                About the event (optional)
              </Typography>
              <Grid item container xs={12} style={{ padding: 10 }}>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <Typography variant="caption" style={{ width: "100%" }}>
                    What is this event about?
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Use this space to tell guests more about this event, e.g., event schedule, speakers, important info, & more."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {event && (
              <Grid
                item
                container
                xs={12}
                style={{
                  borderRadius: 12,
                  background: "white",
                  padding: 10,
                  marginTop: 20,
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    borderBottom: "1px solid grey",
                  }}
                >
                  Tickets
                </Typography>

                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    Add New Ticket
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    label="Ticket Name"
                    placeholder="Ticket Name"
                    value={ticketName}
                    onChange={(e) => setTicketName(e.target.value)}
                    style={{ margin: "10px 0px" }}
                  />

                  <TextField
                    fullWidth
                    label="Ticket Description"
                    placeholder="Ticket Description"
                    value={ticketDescription}
                    onChange={(e) => setTicketDescription(e.target.value)}
                    style={{ marginBottom: 10 }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="Limit per person"
                    placeholder="Limit per person"
                    value={ticketLimit}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && Number(value) >= 0) {
                        setTicketLimit(Math.round(value));
                      }
                    }}
                    style={{ marginBottom: 10 }}
                  />
                  <TextField
                    required
                    fullWidth
                    label="Available Quantity"
                    placeholder="Available Quantity"
                    value={ticketQuantity}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && Number(value) >= 0) {
                        setTicketQuantity(Number(value));
                      }
                    }}
                    style={{ marginBottom: 10 }}
                  />
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{"$"}</InputAdornment>
                      ),
                    }}
                    required
                    label="Ticket Price"
                    placeholder="Ticket Price"
                    value={ticketPrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && Number(value) >= 0) {
                        setTicketPrice(Number(value));
                      }
                    }}
                    style={{ marginBottom: 10, width: "calc(100% - 100px)" }}
                  />
                  <Button
                    variant="outlined"
                    style={{ width: 90, height: 55, marginLeft: 10 }}
                    onClick={() => {
                      setTicketPrice(0.0);
                      setAbsorbFees(false);
                    }}
                  >
                    FREE
                  </Button>

                  <Grid
                    item
                    xs={12}
                    md={discounted ? 6 : 12}
                    lg={discounted ? 4 : 12}
                    style={{ padding: 10 }}
                  >
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Discounted
                    </Typography>
                    <Radio
                      checked={discounted === true}
                      onChange={() => setDiscounted(true)}
                      value={true}
                      name="discounted"
                    />
                    <Typography variant="body2" style={{ display: "inline" }}>
                      Yes
                    </Typography>
                    <Radio
                      checked={discounted === false}
                      onChange={() => {
                        setDiscounted(false);
                        setDiscountType("");
                        setDiscountedValue(0);
                      }}
                      value={false}
                      name="discounted"
                    />
                    <Typography variant="body2" style={{ display: "inline" }}>
                      No
                    </Typography>
                  </Grid>

                  {discounted && (
                    <>
                      <Grid item xs={12} md={6} lg={4} style={{ padding: 10 }}>
                        <Typography
                          required
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Discount Type
                        </Typography>
                        <Radio
                          checked={discountType === "fixed"}
                          onChange={() => setDiscountType("fixed")}
                          value="fixed"
                          name="discountType"
                        />
                        <Typography
                          variant="body2"
                          style={{ display: "inline" }}
                        >
                          Fixed
                        </Typography>
                        <Radio
                          checked={discountType === "percentage"}
                          onChange={() => setDiscountType("percentage")}
                          value="percentage"
                          name="discountType"
                        />
                        <Typography
                          variant="body2"
                          style={{ display: "inline" }}
                        >
                          Percentage
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={4} style={{ padding: 10 }}>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Discount Value
                        </Typography>

                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {discountType === "percentage" ? "%" : "$"}
                              </InputAdornment>
                            ),
                          }}
                          required
                          fullWidth
                          label="Discount Value"
                          placeholder="Enter discount value"
                          value={discountedValue}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (!isNaN(value) && Number(value) >= 0) {
                              setDiscountedValue(value);
                            }
                          }}
                          style={{ marginBottom: 10 }}
                        />
                      </Grid>
                    </>
                  )}
                  {ticketPrice !== "" && ticketPrice > 0 && (
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="caption" style={{ color: "grey" }}>
                        <b>Buyer total:</b> $
                        {absorbFees
                          ? ticketPrice.toFixed(2)
                          : ticketPrice * 0.06 > 4.99
                          ? (ticketPrice + ticketPrice * 0.06).toFixed(2)
                          : (ticketPrice + 4.99).toFixed(2)}{" "}
                        {/* + applicable taxes */}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="secondary"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        How fees work
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ padding: 10, marginBottom: 20 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={ticketPrice === 0 || ticketPrice === ""}
                            checked={absorbFees}
                            onChange={() => setAbsorbFees(!absorbFees)}
                          />
                        }
                        label="Absorb fees: Ticketing fees are deducted from your ticket revenue."
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item container xs={12} style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddTicket}
                      disabled={
                        ticketName === "" ||
                        ticketPrice === "" ||
                        (!!discounted && discountType === "") ||
                        (!!discounted && discountedValue === "") ||
                        ticketLimit === "" ||
                        ticketQuantity === ""
                      }
                    >
                      Add Ticket
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTicketName("");
                        setTicketPrice("");
                        setDiscounted(false);
                        setDiscountType("");
                        setDiscountedValue(0);
                        setTicketLimit("");
                        setTicketQuantity("");
                        setAbsorbFees(false);
                      }}
                      disabled={
                        ticketName === "" &&
                        ticketPrice === "" &&
                        !!discounted &&
                        discountType === "" &&
                        !!discounted &&
                        discountedValue === "" &&
                        ticketLimit === "" &&
                        ticketQuantity === ""
                      }
                      style={{ marginLeft: 5 }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>

                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  {tickets.map((ticket, index) => (
                    <Grid
                      item
                      container
                      xs={12}
                      key={index}
                      style={{
                        padding: 10,
                        height: "fit-content",
                        marginBottom: 10,
                        border: "1px solid lightgrey",
                        borderRadius: 5,
                      }}
                    >
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: "bold" }}
                        >
                          {ticket.name}
                        </Typography>
                        <Typography variant="body2">
                          Price: $
                          {ticket.price === 0
                            ? "FREE"
                            : absorbFees
                            ? ticket.price.toFixed(2) + " (+ fees/tax)"
                            : ticket.price.toFixed(2) + " (+ tax)"}
                        </Typography>
                        <Typography variant="body2">
                          Description: {ticket.description}
                        </Typography>
                        <Typography variant="body2">
                          Quantity: {ticket.quantity}
                        </Typography>
                        <Typography variant="body2">
                          Remaining: {ticket.remaining}
                        </Typography>
                        <Typography variant="body2">
                          Limit per person: {ticket.limit}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          justifyContent: "flex-end",
                          display: "flex",
                          height: "fit-content",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            console.log(index);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDeleteTicket(index)}
                          style={{ marginLeft: 5 }}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>

          <Modal
            open={importOpen}
            onClose={() => {
              setImportOpen(false);
              setImportUrl("");
            }}
          >
            <Grid
              item
              container
              xs={10}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: 20,
                background: "white",
                borderRadius: 12,
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: 20 }}>
                Import from Ticketmaster or Eventbrite
              </Typography>

              <TextField
                disabled={loadingScraper}
                required
                fullWidth
                placeholder="Enter a URL to import from"
                value={importUrl}
                onChange={(e) => {
                  setImportUrl(e.target.value);
                }}
                style={{ marginBottom: 10 }}
                error={
                  importUrl !== "" &&
                  ((importUrl.includes(".com") &&
                    !importUrl.includes("ticketmaster") &&
                    importUrl.includes(".com") &&
                    !importUrl.includes("eventbrite")) ||
                    !importUrl.includes(".com"))
                    ? true
                    : false
                }
                helperText={
                  importUrl !== "" &&
                  ((importUrl.includes(".com") &&
                    !importUrl.includes("ticketmaster") &&
                    importUrl.includes(".com") &&
                    !importUrl.includes("eventbrite")) ||
                    !importUrl.includes(".com"))
                    ? "Please ensure you are using a ticket master or eventbrite URL."
                    : ""
                }
              />

              <Button
                disabled={
                  loadingScraper ||
                  importUrl === "" ||
                  (importUrl !== "" &&
                    ((importUrl.includes(".com") &&
                      !importUrl.includes("ticketmaster") &&
                      importUrl.includes(".com") &&
                      !importUrl.includes("eventbrite")) ||
                      !importUrl.includes(".com")))
                }
                variant="contained"
                color="primary"
                onClick={handleImportEvent}
              >
                {loadingScraper ? "Loading..." : "Import"}
              </Button>
            </Grid>
          </Modal>

          <Modal open={addPromoterModalOpen}>
            <Grid
              item
              container
              xs={10}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: 20,
                background: "white",
                borderRadius: 12,
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: 20,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Is this your event?
              </Typography>
              <Button
                variant={userIsPromoter ? "contained" : "outlined"}
                onClick={() => {
                  setUserIsPromoter(true);
                  setPromoterEmail("");
                }}
              >
                Yes
              </Button>
              <Button
                variant={userIsPromoter === false ? "contained" : "outlined"}
                style={{ marginLeft: 5 }}
                onClick={() => setUserIsPromoter(false)}
              >
                No
              </Button>

              {userIsPromoter === false && (
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Promoter Email
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{
                      marginBottom: 20,
                      width: "100%",
                    }}
                  >
                    <b>Note:</b> Once you have added a promoter, they can not be
                    removed.
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    placeholder="Enter a Promoter Email"
                    value={promoterEmail}
                    onChange={(e) => {
                      setPromoterEmail(e.target.value);
                    }}
                    style={{ marginBottom: 10 }}
                    error={
                      promoterEmail !== "" && !validateEmail(promoterEmail)
                    }
                    helperText={
                      promoterEmail !== "" && !validateEmail(promoterEmail)
                        ? "Please enter a valid email."
                        : ""
                    }
                  />
                  <Button
                    disabled={
                      promoterEmail === "" || !validateEmail(promoterEmail)
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleAddPromoter}
                    style={{ width: "100%" }}
                  >
                    Add Promoter
                  </Button>
                </Grid>
              )}

              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "space-between" }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setImportedEvent(null);
                    setAddPromoterModalOpen(false);
                    setPromoterEmail("");
                    setUserIsPromoter(false);
                  }}
                >
                  Cancel
                </Button>
                {userIsPromoter === true && (
                  <Button
                    variant="contained"
                    style={{}}
                    onClick={handleAddPromoter}
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      </>
    </Modal>
  );
};

export default ViewEditEvent;
