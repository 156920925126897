// src/components/Hero.js
import React, { useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";

const Hero = ({ homeRef }) => {
  const { defaultTheme, navigate, scrollToTop } = useContext(AppContext);

  return (
    <Grid
      ref={homeRef}
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "90dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          containerType: "inline-size",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontWeight: "400",
            color: "grey",
          }}
          sx={{
            fontSize: { xs: "10cqw", md: "5cqw" },
            marginTop: { xs: "-150px", md: "-100" },
          }}
        >
          GLENN
        </Typography>
        <Typography
          variant="h1"
          style={{
            fontWeight: "800",
            color: defaultTheme.palette.black,
          }}
          sx={{ fontSize: { xs: "10cqw", md: "5cqw", marginTop: "-2%" } }}
        >
          JONES
        </Typography>
        <Typography
          variant="h5"
          style={{
            color: "red",
            letterSpacing: "2px",
          }}
          sx={{ fontSize: { xs: "4cqw", md: "1cqw" } }}
        >
          THIS IS LEGENDARY R&B
        </Typography>
        <Button
          onClick={() => {
            navigate("/events");
            scrollToTop();
          }}
          variant="contained"
          style={{
            background: defaultTheme.palette.black,
            color: defaultTheme.palette.white,
            borderRadius: 40,
            height: 40,
            marginTop: 60,
          }}
          sx={{ fontSize: { xs: "3cqw", md: ".75cqw" } }}
        >
          Upcoming Shows
        </Button>
      </div>
    </Grid>
  );
};

export default Hero;
