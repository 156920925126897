// src/components/SignUp.js
import React, { useContext, useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { AppContext } from "../App";
import { db, auth } from "../config/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { EmailAuthProvider } from "firebase/auth";
import { getAuth, linkWithCredential } from "firebase/auth";

const SignUp = ({ isNotAuthPage }) => {
  const {
    dispatch,
    setCurrentUser,
    setUserData,
    navigate,
    scrollToTop,
    setSuccess,
    setError,
    setMessage,
  } = useContext(AppContext);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setErrorMessage] = useState("");

  const handleSignUp = async () => {
    const credential = EmailAuthProvider.credential(email, password);

    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage("Passwords do not match");
      setMessage("Passwords do not match");
      return;
    }

    try {
      //   const userCredential = await createUserWithEmailAndPassword(
      //     auth,
      //     email,
      //     password
      //   );
      //   const user = userCredential.user;
      const auth = getAuth();
      linkWithCredential(auth.currentUser, credential)
        .then(async (usercred) => {
          const user = usercred.user;
          console.log("Anonymous account successfully upgraded", user);

          console.log("User ID => ", user.uid);

          const userData = {
            name,
            phone,
            email,
            uid: user.uid,
            roles: ["user"],
            guest: false,
            test: true,
            imageUrl: "",
          };

          await db.collection("users").doc(userData.uid).set(userData);

          dispatch(setCurrentUser(user));
          dispatch(setUserData(userData));
          setSuccess(true);
          setMessage(
            `Account Created successfully. Welcome, ${name.split(" ")[0]}!`
          );
        })
        .catch((error) => {
          console.log("Error upgrading anonymous account", error);
        });

      if (!isNotAuthPage) {
        navigate("/");
        scrollToTop();
      }
    } catch (error) {
      setError(true);
      setMessage(error.message);
    }
  };

  return (
    <Box sx={{ width: "300px", textAlign: "center" }}>
      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
        Sign Up
      </Typography>
      {error && (
        <Typography color="error" sx={{ marginBottom: "1rem" }}>
          {error}
        </Typography>
      )}
      <TextField
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        label="Full Name"
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        error={!name}
        helperText={!name ? "Name is required" : ""}
      />
      <TextField
        required
        fullWidth
        label="Phone"
        value={phone}
        type="tel"
        onChange={(e) => setPhone(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        error={!phone}
        helperText={!phone ? "Phone number is required" : ""}
      />
      <TextField
        fullWidth
        required
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: "1rem" }}
        error={!email}
        helperText={!email ? "Email is required" : ""}
      />
      <TextField
        fullWidth
        required
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="outlined"
        type="password"
        sx={{ marginBottom: "1rem" }}
        error={password.length > 0 && password.length < 6}
        helperText={
          password.length > 0 && password.length < 6
            ? "Password must be at least 6 characters"
            : ""
        }
      />
      {password && password.length >= 6 && (
        <TextField
          required
          fullWidth
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          variant="outlined"
          type="password"
          sx={{ marginBottom: "1rem" }}
          error={password !== confirmPassword}
          helperText={
            password !== confirmPassword ? "Passwords do not match" : ""
          }
        />
      )}
      <Button
        disabled={
          !name ||
          !email ||
          !phone ||
          !password ||
          password.length < 6 ||
          password !== confirmPassword
        }
        variant="contained"
        fullWidth
        onClick={handleSignUp}
      >
        Sign Up
      </Button>
    </Box>
  );
};

export default SignUp;
