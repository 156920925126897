import React, { useContext, useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {
  HomeOutlined,
  StadiumOutlined,
  ConfirmationNumberOutlined,
  AccountBoxOutlined,
  Home,
  Stadium,
  ConfirmationNumber,
  AccountBox,
} from "@mui/icons-material";
import { AppContext } from "../App";

export default function BottomNavBar() {
  const { navigate, scrollToTop } = useContext(AppContext);
  const [value, setValue] = React.useState("home");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${newValue}`);
    scrollToTop();
  };

  useEffect(() => {
    if (value !== window.location.pathname) {
      let availPaths = ["/", "/events", "/tickets", "/account"];
      if (availPaths.find((path) => path === window.location.pathname)) {
        setValue(window.location.pathname);
      }
    }
  }, [value, window.location.pathname]);

  return (
    <BottomNavigation sx={{ width: 500 }} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Home"
        value="/"
        icon={value === "" ? <Home /> : <HomeOutlined />}
      />
      <BottomNavigationAction
        label="Events"
        value="/events"
        icon={value === "events" ? <Stadium /> : <StadiumOutlined />}
      />
      <BottomNavigationAction
        label="Tickets"
        value="/tickets"
        icon={
          value === "tickets" ? (
            <ConfirmationNumber style={{ rotate: "90deg" }} />
          ) : (
            <ConfirmationNumberOutlined style={{ rotate: "90deg" }} />
          )
        }
      />
      <BottomNavigationAction
        label="Account"
        value="/account"
        icon={value === "accout" ? <AccountBox /> : <AccountBoxOutlined />}
      />
    </BottomNavigation>
  );
}
