// src/screens/ProfileScreen.js
import React from "react";
import { Box } from "@mui/material";
import Profile from "../components/Profile";

const ProfileScreen = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <Profile />
    </Box>
  );
};

export default ProfileScreen;
