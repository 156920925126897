import React, { useEffect, useState, useContext } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button, Grid, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { db } from "../config/firebaseConfig";
import { AppContext } from "../App";

export default function CheckoutForm({
  subTotal,
  fees,
  setDisplayInfo,
  placeOrder,
  firstName,
  lastName,
  email,
  confirmEmail,
  event,
  ticketCounts,
  showSuccess,
  setShowSuccess,
  open,
  setOpen,
  setTicketCounts,
}) {
  const {
    message,
    setMessage,
    setError,
    setSuccess,
    setInfo,
    userData,
    navigate,
    scrollToTop,
  } = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();

  const [stripeMessage, setStripeMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setStripeMessage("Payment succeeded!");
          break;
        case "processing":
          setStripeMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setStripeMessage(
            "Your payment was not successful, please try again."
          );
          break;
        default:
          setStripeMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    console.log("trying to pay..");
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const docTicketsLeft = await db
      .collection("events")
      .doc(event.id)
      .get()
      .then((doc) => {
        const ticketsLeft = doc.data().tickets.map((ticket) => {
          return ticket.remaining;
        });
        return ticketsLeft;
      });

    //map over ticketCounts
    let notEnough = false;
    ticketCounts.forEach((count, idx) => {
      if (count > docTicketsLeft[idx]) {
        notEnough = true;
        setError(true);
        setMessage(
          `We cannot complete your purchase as there are only ${event.tickets[idx].remaining} ${event.tickets[idx].name} tickets left. Please ajjust your order`
        );
        return;
      } else {
        console.log(
          `There are ${event.tickets[idx].remaining} ${event.tickets[idx].name} tickets remaining`
        );
      }
    });

    if (notEnough) {
      return;
    }

    setIsLoading(true);

    const { error, success } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.href}/success`,
      },
    });

    if (success) {
      console.log("success!!");
    }

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setStripeMessage(error.message);
      } else {
        console.log("This is an error =>", error);
        setStripeMessage("An unexpected error occurred.");
      }
    } else {
      console.log("success!!");
      let resetTickets = [...ticketCounts].map((x) => 0);
      setShowSuccess(true);
      setOpen(false);
      setTicketCounts(resetTickets);
      navigate(`${window.location.pathname}/success`);
      scrollToTop();
      //   placeOrder();
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ width: "100%" }}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Grid item container xs={12} style={{ marginTop: 20 }}>
        <Typography
          variant="caption"
          style={{ margin: "10px 0px", width: "100%" }}
        >
          By selecting Place Order, I agree to the TicketShare Terms of Service
        </Typography>
        <Grid item container xs={7}>
          <Button
            onClick={handleSubmit}
            variant={"contained"}
            disabled={
              isLoading ||
              !stripe ||
              !elements ||
              (userData.guest &&
                (!firstName || !lastName || !email || !confirmEmail)) ||
              (email && confirmEmail && email !== confirmEmail)
            }
            id="submit"
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Place Order"
              )}
            </span>
          </Button>
        </Grid>
        <Grid
          item
          container
          xs={5}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <InfoOutlined
            onClick={() => setDisplayInfo(true)}
            style={{ marginRight: 5 }}
          />
          ${(subTotal + fees).toFixed(2)}
        </Grid>
      </Grid>
      {/* Show any error or success messages */}
      {stripeMessage && <div id="payment-message">{stripeMessage}</div>}
    </form>
  );
}
