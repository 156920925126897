export const musicGenres = [
  "Alternative",
  "Blues",
  "Classical",
  "Country",
  "Dance",
  "Electronic",
  "Folk",
  "Funk",
  "Hip-Hop",
  "Heavy Metal",
  "Indie",
  "Jazz",
  "Latin",
  "Metal",
  "Pop",
  "Punk",
  "R&B",
  "Regge",
  "Rock",
  "Soul",
  "Soundtrack",
  "Spoken Word",
  "World",
  "Other",
];
