// src/components/Events.js
import React, { useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AppContext } from "../App";

const Events = () => {
  // Dummy data for events
  const { events } = useContext(AppContext);

  return (
    <Grid
      item
      container
      xs={12}
      style={{ paddingTop: 100, minHeight: "100dvh", alignItems: "flex-start" }}
    >
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Upcoming Events
      </Typography>
      <Grid container spacing={2}>
        {events &&
          events.map((event) => {
            const { title, date, location, image } = event;
            const url = (input) => {
              // Remove all special characters except spaces
              let cleaned = input.replace(/[^\w\s]/gi, "");
              // Replace spaces with hyphens and convert to lowercase
              cleaned = cleaned.replace(/\s+/g, "-").toLowerCase();

              let url = cleaned + `ev_${event.id}`;

              return url;
            };

            return (
              <Grid item xs={12} sm={6} key={event.id}>
                <Card>
                  <CardContent>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ height: { xs: 150, md: "25dvh" } }}
                    >
                      <img
                        src={event.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                    <Typography variant="h6">{event.title}</Typography>
                    <Typography variant="body2">{event.date}</Typography>
                    <Typography variant="body2">{event.location}</Typography>
                    <Button
                      variant="contained"
                      sx={{ marginTop: "1rem" }}
                      component={Link}
                      to={url(title)}
                    >
                      View Details
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}

        {(!events || events?.length === 0) && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", width: "100%", marginTop: 20 }}
          >
            There are no upcoming events at this time.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Events;
