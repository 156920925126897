import React, { useEffect, useState, useContext, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import img4 from "../../assets/91clxEkod9L._UF1000,1000_QL80_.jpg";
import { AppContext } from "../../App";
import {
  Modal,
  Slider,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Pause,
} from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";

const music = [
  {
    name: "We've Only Just Begun",
    artist: "Glenn Jones",
    img: img4,
    url: "https://firebasestorage.googleapis.com/v0/b/ticketshare-16a46.appspot.com/o/GLENN%20JONES%20-%20Weve%20only%20just%20begun.mp3?alt=media&token=d766b3b3-3805-414a-b17f-f119bd971228",
  },
];

const MusicPlayer = () => {
  const { defautTheme, deviceType } = useContext(AppContext);
  const [playing, setPlaying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    deviceType === "mobile" ? false : true
  );
  const [currentSongIdx, setCurrentSongIdx] = useState(0);
  const [volume, setVolume] = useState(0.35);
  const theme = useTheme();
  const audioRef = useRef(new Audio(music[currentSongIdx].url));

  const handlePlayMusic = () => {
    setPlaying((prev) => {
      const newPlaying = !prev;
      if (newPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      return newPlaying;
    });
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue;
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    audioRef.current.volume = volume;
    return () => {
      setPlaying(true);
      //   audioRef.current.play();
    };
  }, []);

  useEffect(() => {
    if (playing) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    // setPlaying(true);
    // audioRef.current.play();
    // return () => {
    //   audioRef.current.pause();
    // };
  }, [currentSongIdx, playing]);

  return (
    <motion.div
      style={{
        position: "absolute",
        bottom: deviceType === "mobile" ? "12%" : "5%",
        right: "5%",
        zIndex: 50,
        width: "fit-content",
      }}
    >
      <AnimatePresence>
        {isExpanded ? (
          <motion.div
            key="expanded"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <Card
              sx={{
                display: "flex",
                overflow: "visible",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  flexDirection: "column",
                }}
              >
                <IconButton
                  onClick={toggleExpand}
                  style={{
                    position: "absolute",
                    top: -25,
                    left: -25,
                    zIndex: 1,
                    background: "white",
                  }}
                >
                  <Close />
                </IconButton>
                <CardContent
                  sx={{ flex: "1 0 auto", pl: 1, pt: 1, pr: 1, pb: 0 }}
                >
                  <Typography
                    component="div"
                    variant="caption"
                    style={{ fontWeight: "bold", padding: 0 }}
                  >
                    {music[currentSongIdx].name}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    pl: 1,
                    pr: 1,
                    pb: 1,
                  }}
                >
                  <IconButton aria-label="previous">
                    {theme.direction === "rtl" ? (
                      <SkipNextIcon />
                    ) : (
                      <SkipPreviousIcon />
                    )}
                  </IconButton>
                  <IconButton aria-label="play/pause" onClick={handlePlayMusic}>
                    {!playing ? (
                      <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                    ) : (
                      <Pause sx={{ height: 38, width: 38 }} />
                    )}
                  </IconButton>
                  <IconButton aria-label="next">
                    {theme.direction === "rtl" ? (
                      <SkipPreviousIcon />
                    ) : (
                      <SkipNextIcon />
                    )}
                  </IconButton>
                  <Slider
                    aria-label="Volume"
                    value={volume}
                    onChange={handleVolumeChange}
                    min={0}
                    max={1}
                    step={0.01}
                    sx={{ width: 80, ml: 2 }}
                  />
                </Box>
              </Box>
              <CardMedia
                component="img"
                sx={{ width: { xs: 90 } }}
                image={music[currentSongIdx].img}
                alt="Glenn Jones"
              />
            </Card>
          </motion.div>
        ) : (
          <motion.div
            key="collapsed"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1, width: 60 }}
            exit={{ opacity: 0, scale: 0.8, width: 60 }}
            transition={{ duration: 0.3 }}
          >
            <Box
              sx={{
                background: "white",
                padding: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <KeyboardArrowLeft
                style={{ marginRight: 5 }}
                onClick={toggleExpand}
              />
              {!playing ? (
                <PlayArrowIcon onClick={handlePlayMusic} />
              ) : (
                <Pause onClick={handlePlayMusic} />
              )}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default MusicPlayer;
