import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../config/firebaseConfig";

const CheckInTicket = () => {
  const paths = window.location.pathname.split("/").filter((x) => x.length > 0);
  const eventId = paths[0];
  const userId = paths[1];
  const ticketId = paths[2];
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    db.collection("users")
      .doc(userId)
      .collection("tickets")
      .where("ticketId", "==", ticketId)
      .get()
      .then((query) => {
        let tickets = query.docs.map((doc) => {
          return doc.data();
        });
        setTicket(tickets[0]);
      });
  }, []);

  useEffect(() => {
    console.log("TICKET IS => ", ticket);
  }, [ticket]);

  return (
    <Grid
      item
      contianer
      xs={12}
      style={{
        height: "100dvh",
        paddingTop: "100px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography>Ticket Check-in</Typography>
      <Grid
        item
        container
        xs={12}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button>Check-in</Button>
      </Grid>
    </Grid>
  );
};

export default CheckInTicket;
