import React, { useContext } from "react";
import {
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormGroup,
  FormControlLabel,
  Link,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";
import img11 from "../assets/glenn-jonespromo-photowhite-suit-with-guitar07262018-copy.jpeg";

const About = () => {
  const { defaultTheme, artist } = useContext(AppContext);

  return (
    <Grid
      item
      container
      xs={12}
      //   sx={{ height: { xs: "100dvh", md: "70dvh" } }}
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={5}
        sx={{
          minHeight: { xs: "70dvh", md: "60dvh" },
          maxHeight: { md: "90dvh" },
        }}
        style={{
          textAlign: "center",
          background: "transparent",
          padding: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={img11}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          minHeight: { xs: "70dvh", md: "60dvh" },
          maxHeight: { md: "90dvh" },
        }}
        style={{
          textAlign: "center",
          background: defaultTheme.palette.white,
          padding: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          About Glenn
        </Typography>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {artist.bio}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default About;
