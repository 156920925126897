import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Grid, Stack, Modal, IconButton } from "@mui/material";
import AnalyticCard from "./AnalyticCard";
import OrdersTable from "./OrdersTable";
import MainCard from "./MainCard";
import ManageEvents from "./ManageEvents";
import { AppContext } from "../App";
import { db } from "../config/firebaseConfig";
import WeeklyBarChart from "./WeeklyBarChart";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Dashboard = () => {
  const { userData } = useContext(AppContext);
  const [orders, setOrders] = useState(null);
  const [totalTicketsSold, setTotalTicketsSold] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [weeklyRevenue, setWeeklyRevenue] = useState(0);
  const [previousWeeklyRevenue, setPreviousWeeklyRevenue] = useState(0);
  const [weeklyTicketsSold, setWeeklyTicketsSold] = useState(0);
  const [previousWeeklyTicketsSold, setPreviousWeeklyTicketsSold] = useState(0);
  const [weeklyOrders, setWeeklyOrders] = useState(0);
  const [previousWeeklyOrders, setPreviousWeeklyOrders] = useState(0);
  const [chartData, setChartData] = useState({
    seriesData: [],
    categories: [],
  });

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [weekIndex, setWeekIndex] = useState(0); // 0 means current week, -1 means last week, etc.

  const fetchOrders = async () => {
    await db
      .collection("artists")
      .doc(userData.uid)
      .collection("orders")
      .orderBy("purchaseDate", "desc")
      .get()
      .then((query) => {
        const data = query.docs.map((doc) => doc.data());
        setOrders(data);
      });
  };

  const calculateOrderData = (orders) => {
    const totalTicketsSold = orders.reduce(
      (acc, order) => acc + order.tickets.length,
      0
    );
    const totalOrders = orders.length;
    const totalRevenue = orders.reduce(
      (acc, order) => acc + order.orderTotal,
      0
    );
    const uniqueUserIds = new Set(orders.map((order) => order.purchaserId));
    const totalUsers = uniqueUserIds.size;

    return { totalTicketsSold, totalOrders, totalRevenue, totalUsers };
  };

  const processChartData = (orders, weekIndex) => {
    const now = new Date();
    const startOfWeek = new Date(
      now.setDate(now.getDate() - now.getDay() + 7 * weekIndex)
    );
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const previousWeekStart = new Date(startOfWeek);
    previousWeekStart.setDate(previousWeekStart.getDate() - 7);
    const previousWeekEnd = new Date(endOfWeek);
    previousWeekEnd.setDate(previousWeekEnd.getDate() - 7);

    const categories = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const seriesData = [0, 0, 0, 0, 0, 0, 0];
    let weeklyTotalRevenue = 0;
    let previousWeeklyTotalRevenue = 0;
    let weeklyOrders = 0;
    let previousWeeklyOrders = 0;
    let weeklyTicketsSold = 0;
    let previousWeeklyTicketsSold = 0;

    orders.forEach((order) => {
      const purchaseDate = new Date(order.purchaseDate.toDate());
      if (purchaseDate >= startOfWeek && purchaseDate <= endOfWeek) {
        const day = purchaseDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        seriesData[day] += order.orderTotal;
        weeklyTotalRevenue += order.orderTotal;
        weeklyOrders += 1;
        weeklyTicketsSold += order.tickets.length;
      } else if (
        purchaseDate >= previousWeekStart &&
        purchaseDate <= previousWeekEnd
      ) {
        previousWeeklyTotalRevenue += order.orderTotal;
        previousWeeklyOrders += 1;
        previousWeeklyTicketsSold += order.tickets.length;
      }
    });

    setWeeklyRevenue(weeklyTotalRevenue);
    setPreviousWeeklyRevenue(previousWeeklyTotalRevenue);
    setWeeklyOrders(weeklyOrders);
    setPreviousWeeklyOrders(previousWeeklyOrders);
    setWeeklyTicketsSold(weeklyTicketsSold);
    setPreviousWeeklyTicketsSold(previousWeeklyTicketsSold);

    return { seriesData, categories };
  };

  useEffect(() => {
    if (userData && !userData.onboarded) {
      setOnboardingModalOpen(true);
    }

    if (!orders && userData) {
      fetchOrders();
    }
    if (orders) {
      const { totalTicketsSold, totalOrders, totalRevenue, totalUsers } =
        calculateOrderData(orders);
      setTotalTicketsSold(totalTicketsSold);
      setTotalOrders(totalOrders);
      setTotalRevenue(totalRevenue);
      setTotalUsers(totalUsers);

      const chartData = processChartData(orders, weekIndex);
      setChartData(chartData);
    }
  }, [orders, userData, weekIndex]);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return 100;
    return ((current - previous) / previous) * 100;
  };

  const weeklyPercentageChange = calculatePercentageChange(
    weeklyRevenue,
    previousWeeklyRevenue
  );
  const weeklyExtraRevenue = weeklyRevenue - previousWeeklyRevenue;
  const weeklyPercentageChangeOrders = calculatePercentageChange(
    weeklyOrders,
    previousWeeklyOrders
  );
  const weeklyExtraOrders = weeklyOrders - previousWeeklyOrders;
  const weeklyPercentageChangeTicketsSold = calculatePercentageChange(
    weeklyTicketsSold,
    previousWeeklyTicketsSold
  );
  const weeklyExtraTicketsSold = weeklyTicketsSold - previousWeeklyTicketsSold;

  const handlePreviousWeek = () => {
    setWeekIndex((prev) => prev - 1);
  };

  const handleNextWeek = () => {
    setWeekIndex((prev) => (prev === 0 ? 0 : prev + 1)); // Prevent going beyond current week
  };

  return (
    <Box style={{ width: "100dvw", paddingTop: 120, background: "white" }}>
      <Grid item container xs={12} style={{ padding: 10 }}>
        <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
          Dashboard
        </Typography>
        <ManageEvents isArtist={userData?.roles?.includes("artist")} />
        <Grid container spacing={2}>
          <Typography
            variant="h5"
            sx={{ marginBottom: "1rem", width: "100%", pl: 2, pr: 2 }}
          >
            Sales Analytics
          </Typography>
          {/* Map over dashboard items here */}
          <Grid item xs={12} md={3}>
            <AnalyticCard
              title="Total Users"
              count={totalUsers.toString()}
              percentage={0}
              extra={0}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <AnalyticCard
              title="Total Tickets Sold"
              count={totalTicketsSold.toString()}
              percentage={weeklyPercentageChangeTicketsSold.toFixed(2)}
              isLoss={weeklyPercentageChangeTicketsSold
                .toFixed(2)
                .includes("-")}
              extra={weeklyExtraTicketsSold.toString()}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <AnalyticCard
              title="Total Orders"
              count={totalOrders.toString()}
              percentage={weeklyPercentageChangeOrders.toFixed(2)}
              isLoss={weeklyPercentageChangeOrders.toFixed(2).includes("-")}
              extra={weeklyExtraOrders.toString()}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <AnalyticCard
              title="Total Revenue"
              count={`$${totalRevenue.toFixed(2)}`}
              percentage={weeklyPercentageChange.toFixed(2)}
              isLoss={weeklyPercentageChange.toFixed(2).includes("-")}
              extra={weeklyExtraRevenue.toFixed(2)}
            />
          </Grid>
        </Grid>

        {/* row 3 */}
        {orders && (
          <>
            <Grid item container xs={12} md={7}>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h5">Recent Orders</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <Grid item container xs={12} style={{ padding: 10 }}>
                <MainCard
                  sx={{ mt: 2, width: "100%", height: "55dvh" }}
                  content={false}
                >
                  <OrdersTable orders={orders} />
                </MainCard>
              </Grid>
            </Grid>

            <Grid item container xs={12} md={5} style={{ padding: 10 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h5">Income Overview</Typography>
                </Grid>
                <Grid item />
              </Grid>
              <MainCard
                sx={{ mt: 2, p: 1, width: "100%", height: "55dvh" }}
                content={false}
              >
                <Box sx={{ pb: 0 }}>
                  <Stack spacing={2} style={{}}>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      {weekIndex === 0
                        ? "This Week"
                        : weekIndex === -1
                        ? "Last Week"
                        : weekIndex * -1 + " Weeks Prior"}{" "}
                      Statistics{" "}
                      {weekIndex !== 0 && (
                        <span
                          onClick={() => setWeekIndex(0)}
                          style={{
                            fontSize: 12,
                            padding: 5,
                            background: "lightgrey",
                            borderRadius: 5,
                            marginLeft: 5,
                            cursor: "pointer",
                          }}
                        >
                          TODAY
                        </span>
                      )}
                    </Typography>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-around"}
                    >
                      <IconButton onClick={handlePreviousWeek}>
                        <KeyboardArrowLeft />
                      </IconButton>
                      <Typography variant="h4" style={{ margin: "0px 10px" }}>
                        ${weeklyRevenue.toFixed(2)}
                      </Typography>
                      <IconButton
                        disabled={weekIndex === 1}
                        onClick={handleNextWeek}
                      >
                        <KeyboardArrowRight />
                      </IconButton>
                    </Grid>
                  </Stack>
                </Box>
                <WeeklyBarChart
                  seriesData={chartData.seriesData}
                  categories={chartData.categories}
                />
              </MainCard>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;
