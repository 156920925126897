// src/components/ManageEvents.js
import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  AccordionActions,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ViewEditEvent from "./ViewEditEvent";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AppContext } from "../App";

const ManageEvents = () => {
  const [viewEditEventOpen, setViewEditEventOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { events, setEvents } = useContext(AppContext);

  const handleViewEditEventClose = () => {
    setViewEditEventOpen(!viewEditEventOpen);
    setSelectedEvent(null);
  };

  const cancelEvent = (idx) => {
    let updatedEvents = events.slice();
    updatedEvents.splice(idx, 1);
    setEvents(updatedEvents);
  };

  return (
    <Grid item container xs={12} sx={{ marginBottom: "2rem" }}>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<KeyboardArrowDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Manage Events {events?.length > 0 ? `(${events.length})` : "(0)"}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ marginBottom: "1rem" }}>Upcoming Events</Typography>
          <Button
            variant="contained"
            sx={{ marginBottom: "1rem", ml: 2 }}
            onClick={() => setViewEditEventOpen(true)}
          >
            Create New Event
          </Button>
          <Grid container spacing={2} style={{ padding: 10 }}>
            {/* Map over events here */}
            {events &&
              events.length > 0 &&
              events.map((event, idx) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    style={{
                      padding: 10,
                    }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        padding: 10,
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        height: 120,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={3}
                        style={{
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={event.image}
                          alt={""}
                          style={{ height: "100%", width: "auto" }}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Box sx={{}}>
                          <Typography variant="h6">{event.title}</Typography>
                          <Typography>
                            {event.date} @ {event.time}
                          </Typography>
                          <Grid
                            item
                            container
                            xs={12}
                            justifyContent={"flex-end"}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => cancelEvent(idx)}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => {
                                setSelectedEvent(event);
                                setViewEditEventOpen(true);
                              }}
                              variant="contained"
                              color="secondary"
                              style={{ marginLeft: 5 }}
                            >
                              Edit
                            </Button>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <ViewEditEvent
        open={viewEditEventOpen}
        setClose={handleViewEditEventClose}
        event={selectedEvent}
        events={events}
        setEvents={setEvents}
      />
    </Grid>
  );
};

export default ManageEvents;
