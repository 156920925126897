// src/screens/EventScreen.js
import React, { useEffect, useState, useContext, useRef } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import EventOverview from "../components/EventOverview";
import Ticket from "../components/Ticket";
import Purchase from "../components/Purchase";
import CheckoutModal from "../components/CheckoutModal";
import { AppContext } from "../App";
import SignUp from "../components/SignUp";

const EventScreen = () => {
  const { events, userData } = useContext(AppContext);
  const [event, setEvent] = useState(null);
  const [ticketCounts, setTicketCounts] = useState([]);
  const [total, setTotal] = useState(null);

  const [clientSecret, setClientSecret] = useState("");
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const tixRef = useRef(null);

  const path = window.location.pathname;
  console.log(path);

  useEffect(() => {
    if (!event) {
      console.log("checking for event");
      const selectedEvent = events.find((e) =>
        path.split("/")[2].includes(e.id)
      );
      if (selectedEvent) {
        setEvent(selectedEvent);
        setTicketCounts(selectedEvent.tickets.map(() => 0));
        if (path.split("/").length >= 4) {
          console.log("this is where we are");
          const success = path.split("/")[3].includes("success");
          if (success) {
            setShowSuccess(true);
          }
        }
      }
    } else {
      if (path.split("/").length >= 4) {
        console.log("this is where we are");
        const success = path.split("/")[3].includes("success");
        if (success) {
          setShowSuccess(true);
        }
      }
    }
  }, [event]);

  useEffect(() => {
    if (ticketCounts.length > 0) {
      let total = 0;
      ticketCounts.forEach((count, idx) => {
        if (event.tickets[idx].price !== 0) {
          total += count * (event.tickets[idx].price + 4.99);
        } else {
          total += count * event.tickets[idx].price;
        }
      });
      console.log("TOTAL", total);
      setTotal(total);
    }
  }, [ticketCounts]);

  useEffect(() => {
    console.log("Thie client secret is =>", clientSecret);
    if (clientSecret) {
      setLoading(false);
    }
  }, [clientSecret]);

  return (
    <Grid
      item
      container
      xs={12}
      md={10}
      lg={8}
      xl={6}
      sx={{ paddingTop: "100px", flexFlow: "column", background: "white" }}
    >
      {event && (
        <>
          <Box sx={{ marginBottom: "2rem" }}>
            <Grid
              imte
              container
              xs={12}
              sx={{ height: { xs: 200, md: "38dvh", padding: 10 } }}
            >
              <img
                src={event.image || ""}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 12,
                }}
              />
            </Grid>
          </Box>
          <EventOverview event={event} />
          <Grid item container xs={12} direction={"row"}>
            <Grid item container xs={12} md={8}>
              <Ticket
                tixRef={tixRef}
                event={event}
                ticketCounts={ticketCounts}
                setTicketCounts={setTicketCounts}
              />
            </Grid>
            <Grid
              item
              container
              xs={4}
              sx={{ display: { xs: "none", md: "flex" } }}
              style={{ padding: 10 }}
            >
              <Purchase
                loading={loading}
                setLoading={setLoading}
                userData={userData}
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                ticketCounts={ticketCounts}
                event={event}
                total={total}
                setCheckoutOpen={setCheckoutOpen}
                scrollToSection={() => {
                  tixRef.current.scrollIntoView({ behavior: "smooth" });
                }}
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              sx={{ display: { xs: "flex", md: "none" } }}
              style={{
                padding: 10,
                position: "absolute",
                bottom: "5%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                zIndex: 5,
              }}
            >
              <Purchase
                loading={loading}
                setLoading={setLoading}
                scrollToSection={() => {
                  tixRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                setCheckoutOpen={setCheckoutOpen}
                ticketCounts={ticketCounts}
                event={event}
                total={total}
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                userData={userData}
              />
            </Grid>
          </Grid>

          <CheckoutModal
            open={checkoutOpen}
            setOpen={setCheckoutOpen}
            event={event}
            ticketCounts={ticketCounts}
            setTicketCounts={setTicketCounts}
            total={total}
            clientSecret={clientSecret}
            setClientSecret={setClientSecret}
            showSuccess={showSuccess}
            setShowSuccess={setShowSuccess}
          />
        </>
      )}

      <Modal open={showSuccess} onClose={() => setShowSuccess(false)}>
        <Grid
          item
          container
          xs={11}
          md={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            borderRadius: 12,
            padding: 20,
            outline: "none",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            style={{ width: "100%", textAlign: "center" }}
          >
            Success!!
          </Typography>
          <Typography style={{ width: "100%", textAlign: "center" }}>
            Thank you for your purchase!
          </Typography>
          <Typography style={{ width: "100%", textAlign: "center" }}>
            Your order should appear in your email shortly. Please check your
            email for any updates.
          </Typography>
          {userData?.guest ? (
            <>
              <Typography
                style={{ width: "100%", textAlign: "center", marginTop: 20 }}
              >
                Create Account to access your tickets anytime.
              </Typography>
              <SignUp />
            </>
          ) : (
            <Button onClick={() => setShowSuccess(false)}>Close</Button>
          )}
        </Grid>
      </Modal>
    </Grid>
  );
};

export default EventScreen;
