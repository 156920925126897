import PropTypes from "prop-types";
// material-ui
import {
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
} from "@mui/material";

// third-party
import { NumericFormat } from "react-number-format";

// project import
import Dot from "./@extended/Dot";

function createData(order_no, purchaser_name, show, total_tix, earnings) {
  return { order_no, purchaser_name, show, total_tix, earnings };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "order_no",
    align: "left",
    disablePadding: false,
    label: "Order No.",
  },
  {
    id: "purchaser_name",
    align: "left",
    disablePadding: true,
    label: "Purchaser Name",
  },
  {
    id: "show",
    align: "left",
    disablePadding: false,
    label: "Show",
  },
  {
    id: "total_tix",
    align: "right",
    disablePadding: false,
    label: "Total Tickets",
  },
  {
    id: "earnings",
    align: "right",
    disablePadding: false,
    label: "Revenue",
  },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = { order: PropTypes.any, orderBy: PropTypes.string };

function OrderStatus({ status }) {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
}

OrderStatus.propTypes = { status: PropTypes.number };

// ==============================|| ORDER TABLE ||============================== //

export default function OrderTable({ orders }) {
  const order = "asc";
  const orderBy = "order_no";

  console.log("ORDERS => ", orders);

  const rows = orders.map((order) => {
    return createData(
      order.orderNumber,
      order.purchaserName,
      order.eventName,
      order.tickets.length,
      order.orderTotal
    );
  });

  console.log("ROWS => ", rows);

  return (
    <Box>
      <Grid item container xs={12}>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            "& td, & th": { whiteSpace: "nowrap" },
          }}
        >
          <Table aria-labelledby="tableTitle">
            <OrderTableHead order={order} orderBy={orderBy} />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      tabIndex={-1}
                      key={row.order_no}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        <Link color="secondary"> {row.order_no}</Link>
                      </TableCell>
                      <TableCell>{row.purchaser_name}</TableCell>
                      <TableCell>
                        {row.show}
                        {/* <OrderStatus status={row.show} /> */}
                      </TableCell>
                      <TableCell align="right">{row.total_tix}</TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={row.earnings}
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      purchaserName: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      tickets: PropTypes.arrayOf(PropTypes.object).isRequired,
      orderTotal: PropTypes.number.isRequired,
    })
  ).isRequired,
};
