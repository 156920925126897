// src/screens/SettingsScreen.js
import React from "react";
import { Box } from "@mui/material";
import Settings from "../components/Settings";

const SettingsScreen = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <Settings />
    </Box>
  );
};

export default SettingsScreen;
