import React, { useEffect } from "react";
import { motion } from "framer-motion";
import glenn from "../assets/glenn-nobg.png";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const SplashScreen = ({}) => {
  return (
    <Grid
      item
      container
      xs={12}
      md={6}
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <motion.div
        animate={{ opacity: 1, top: "50%" }}
        transition={{ duration: 1, delay: 0.25 }}
        style={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "55%",
          height: "fit-contet",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          opacity: 0,
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            containerType: "inline-size",
          }}
        >
          <img
            src={glenn}
            alt=""
            style={{
              width: "50%",
              height: "auto",
              objectFit: "contain",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          />
          <div
            style={{
              width: "50%",
              height: "50%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2,
              background: "black",
            }}
          />
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              color: "black",
              position: "absolute",
              bottom: "50%",
              left: "50%",
              transform: "translate(-220%, -220%)",
              fontSize: "10cqw",
              rotate: "-12deg",
              zIndex: 0,
            }}
          >
            THE
          </Typography>
          <Typography
            variant="h2"
            style={{
              //   fontWeight: "bold",
              color: "grey",
              position: "absolute",
              top: "20%",
              left: "50%",
              transform: "translate(-50%, -100%)",
              fontSize: "40cqw",
              rotate: "-12deg",
              zIndex: 0,
            }}
          >
            GLENN
          </Typography>
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              color: "black",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -35%)",
              fontSize: "40cqw",
              rotate: "-12deg",
              zIndex: 2,
            }}
          >
            JONES
          </Typography>
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              color: "red",
              position: "absolute",
              bottom: "50%",
              left: "50%",
              transform: "translate(10%, 325%)",
              fontSize: "10cqw",
              rotate: "-12deg",
              zIndex: 2,
            }}
          >
            EXPERIENCE
          </Typography>
        </div>
      </motion.div>
      <motion.p
        animate={{ opacity: 1, bottom: "3%" }}
        transition={{ duration: 0.5, delay: 1 }}
        variant="caption"
        style={{
          position: "absolute",
          left: "50%",
          bottom: "-5%",
          transform: "translate(-50%, 0%)",
          color: "black",
          opacity: 0,
          fontSize: ".75rem",
          textAlign: "center",
        }}
      >
        Powered by TicketShare.
        <br />
        COMING SOON!
      </motion.p>
    </Grid>
  );
};

export default SplashScreen;
